import { ResourceType } from '@iehr/fhirtypes';
import { Document, ResourceHistoryTable, useIEHR } from '@iehr/react';
import { useParams } from 'react-router';

export function HistoryPage(): JSX.Element | null {
  const iehr = useIEHR();
  const { resourceType, id } = useParams() as { resourceType: ResourceType; id: string };
  const history = iehr.readHistory(resourceType, id).read();

  return (
    <Document>
      <ResourceHistoryTable history={history} />
    </Document>
  );
}
